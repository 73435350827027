import React, { useEffect, useState } from "react";
import ChoosePlans from "./ChoosePlans";
import ViewPlans from "./ViewPlans";

function BillingController({ children }) {
  const [mountedStep, setMountedStep] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    setMountedStep(children);
  }, [children]);

  return (
    <div className="text-center h-full flex flex-1 flex-col justify-center bg-slate-100">
      {mountedStep && <div>{mountedStep}</div>}

    </div>
  );
}

export default BillingController;
