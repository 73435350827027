import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("token");
}

export function getCouses(page) {
  var url = process.env.REACT_APP_API_BASE_URL + "/courses?page=" + page + "&page_size=100";
  return axios.get(url);
}

export function getRegistrations(page) {
  var url = process.env.REACT_APP_API_BASE_URL + "/user/courses/registrations?page=" + page + "&page_size=100";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function courseRegister(course_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/courses/course/register";
  return axios.post(url, { course_id: course_id }, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function newPalace(name, image = null, color = null) {
  var url = process.env.REACT_APP_API_BASE_URL + "/palaces/new";
  return axios.post(url, { name: name, color, image }, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function getCertificateData(course_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/course/certificate"
  return axios.post(url, { course_id: course_id }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function setCertificate(registration_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/course/certificate/activate"
  return axios.post(url, { registration_id: registration_id }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getCourse(course_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/course/" + course_id
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });

}

export function get_all_user_stats(api_key) {
  var url = process.env.REACT_APP_API_BASE_URL + "/all_users"
  return axios.get(url, { headers: { 'x-api-key': api_key } });
}

export function get_users_stats_by_course(api_key, course_name) {
  var url = process.env.REACT_APP_API_BASE_URL + "/registered_users/" + course_name
  return axios.get(url, { headers: { 'x-api-key': api_key } });
}
