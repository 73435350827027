import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { initAmplitude } from "./utils/amplitude";
import ReactGA from "react-ga"
initAmplitude();
ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
