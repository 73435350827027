import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  BoltIcon, ChatBubbleBottomCenterTextIcon, GlobeAltIcon, ScaleIcon, CheckIcon
} from '@heroicons/react/24/outline'
import logo_horizontal_branco from '../../Assets/logo_horizontal_branco.png'
import landing from '../../Assets/landing.png'
import showcaseimage from '../../Assets/showcaseimage.png'
import antonio from '../../Assets/antonio.jpeg'
import rita from '../../Assets/rita.png'
import carlos from '../../Assets/carlos.png'
import jd from '../../Assets/jd.png'
import lr from '../../Assets/livroreclamacoes.png'
import ReactGA from "react-ga"

const navigation = [

]
const features = [
  {
    name: 'Cursos Creditados (BREVEMENTE)',
    href: '#',
    description: 'Aprende com os nossos cursos certificados pela Ordem dos Farmacêuticos',
    icon: ChartBarIcon,
  },
  {
    name: 'Webinares (BREVEMENTE)',
    href: '#',
    description: 'Revê os webinares do último ano',
    icon: Squares2X2Icon,
  },
  {
    name: 'Atendimento na Farmácia',
    href: '#',
    description: 'Melhora as tuas capacidades de atendimento com os nossos temas adaptados aos desafios do dia-a-dia na farmácia comunitária',
    icon: ChartBarIcon,
  },
  {
    name: 'Soft-Skills',
    href: '#',
    description: 'Melhora as tuas capacidades de comunicação, gestão de conflitos e resolução de problemas para uma relação fantástica com a tua equipa',
    icon: Squares2X2Icon,
  },
]

const socials = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/academiaimphorma',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/academiaimphorma',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },

]

const people = [
  {
    name: 'Rita Ladeira',
    role: 'Farmacêutica Comunitária',
    linkedinUrl: 'https://www.linkedin.com/in/ritaladeira1996',
    imageUrl: rita
  },
  {
    name: 'Carlos Silva',
    role: 'Farmacêutico Comunitário',
    linkedinUrl: 'https://www.linkedin.com/in/carlos-daniel-domingos-silva-435384129',
    imageUrl: carlos
  },
  {
    name: 'João Ferreira',
    role: 'Developer Full-Stack',
    linkedinUrl: 'https://www.linkedin.com/in/jdcferreira',
    imageUrl: jd
  },
  {
    name: 'António Sequeira',
    role: 'Developer Back-End',
    linkedinUrl: 'https://www.linkedin.com/in/antónio-sequeira-bb227b113',
    imageUrl: antonio
  },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  })
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative isolate overflow-hidden bg-gradient-to-b  from-black to-d-iteal/90 sm:to-d-iteal/60 " >

          <img
            src={landing}
            className="absolute inset-0  h-full w-full object-cover mix-blend-overlay -z-10"
          />


          <div className="px-6 lg:px-8 z-20">
            <nav className="flex items-center justify-between pt-6" aria-label="Global">
              <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Academia imPHorma</span>
                  <img className="h-10 w-auto lg:h-16" src={logo_horizontal_branco} alt="" />
                </a>
              </div>
              <div className="flex lg:hidden">
                <div className=" lg:flex lg:flex-1 lg:justify-end">
                  <a href="/login" className="text-sm font-normal leading-6 text-white">
                    Login <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-lg font-semibold leading-6 text-white">
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <a href="/login" className="text-xl font-semibold leading-6 text-white">
                  Login <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </nav>

            <div className="mx-auto max-w-4xl pt-48 pb-12 sm:pt-64 sm:pb-24 lg:pt-56 lg:pb-32 z-20">
              <div className="text-center ">
                <p className="text-4xl font-normal tracking-tight text-white sm:text-6xl ">
                  Bem-vindo à

                </p>
                <p className='text-4xl font-bold  text-white sm:text-7xl '>Academia imPHorma</p>
                <div className='w-full flex justify-center'>
                  <p className="mt-6 text-lg leading-8 text-white z-20 max-w-xl">
                    A plataforma da tua academia chegou! Encontra aqui os melhores cursos para profissionais de farmácia e aprende ao teu ritmo, onde quer que estejas.
                  </p>
                </div>
                <div className="mt-10 flex items-center justify-center gap-x-6 ">
                  <a
                    href="/register"
                    className="rounded-md bg-iteal px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-d-iteal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-d-iteal z-20"
                  >
                    Registar Agora
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-iteal">A nossa plataforma</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Aprende online</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Na nossa plataforma tens acesso a todos os cursos online. Podes aprender ao teu ritmo e depois só tens de descarregar o certificado.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute top-1 left-1 h-5 w-5 text-iteal" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src={showcaseimage}
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>

      <div className="bg-zinc-900 py-24 sm:py-32">
        <div className="mx-auto max-w-full px-6 text-center lg:px-8 flex flex-col">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">A nossa equipa</h2>
            <p className="mt-4 text-xl leading-8 text-gray-400">
              A nossa equipa é constituída por farmacêuticos comunitários e engenheiros informáticos. Juntos queremos dar-te a melhor plataforma online de aprendizagem e formação na área da farmácia. Curioso?
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20  w-full flex justify-around  flex-wrap max-w-2xl lg:max-w-5xl  2xl:max-w-7xl"
          >
            {people.map((person) => (
              <li key={person.name} className="rounded-2xl bg-zinc-800 py-10 px-8 mx-4 my-4">
                <img className="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-iteal">{person.name}</h3>
                <p className="text-sm leading-6 text-gray-400">{person.role}</p>
                <ul role="list" className="mt-6 flex justify-center gap-x-6">
                  <li>
                    <a href={person.linkedinUrl} target="_blank" className="text-gray-400 hover:text-gray-300">
                      <span className="sr-only">LinkedIn</span>
                      <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-zinc-900">
        <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight text-white">
              Começa agora a aprender com a nossa plataforma
              <br />

            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              Regista-te para teres acesso a todas as novidades.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/register"
                className="rounded-md bg-iteal px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-gray-100 hover:text-iteal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Registar agora
              </a>

            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1024 1024"
          className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          aria-hidden="true"
        >
          <circle cx={512} cy={512} r={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.9" />
          <defs>
            <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
              <stop stopColor="#000000" />
              <stop offset={1} stopColor="#53b3b3" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <footer className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">

          <div className="flex justify-center space-x-6 md:order-3">
            {socials.map((item) => (
              <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />

              </a>

            ))}

          </div>
          <a href='https://www.livroreclamacoes.pt/'><img src={lr} className="h-10 w-18 " /></a>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-base text-gray-400">&copy; 2023 Travessia Alquímica, LDA. Todos os direitos reservados.</p>


          </div>
        </div>
      </footer>
    </div>
  )
}
