import React, { useEffect, useState } from "react";
import { changePassword, login, requestPasswordChange } from "../../Requests/User/UserRequests";
import { useNavigate } from "react-router-dom";
import landing from '../../Assets/human1.png'
import Logo_Vertical_SF from "../../Assets/Logo_Vertical_SF.png";
import Cookies from "universal-cookie";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SuccesModal from "../../Components/SuccesModal";
import ErrorModal from "../../Components/ErrorModal";

function PasswordChange() {

  const [goToLogin, setGoToLogin] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [feedbackModal, setFeedbackModal] = useState(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);


  let handleSubmit = (event) => {
    setIsLoading(true)
    setFeedbackModal(false)
    event.preventDefault();
    // Verify if passwords match
    let confirmation_token = searchParams.get(
      "confirmation_token"
    )
    let response = changePassword(confirmation_token, event.target.password.value, event.target.confirmPassword.value);
    response
      .then((res) => {
        setIsLoading(false)
        setFeedbackModal(
          <SuccesModal
            title={t('PasswordChangePage.SuccessModalTitle')}
            body={t('PasswordChangePage.SuccessModalText')}
            continue={t('PasswordChangePage.SuccessModalButton')}
            navLink="/login"
          />
        );
      })
      .catch((error) => {
        setIsLoading(false)
        error?.response?.data?.errors?.password ? (
          setFeedbackModal(
            <ErrorModal
              title={t('RegisterPage.FailModalTitle')}
              body={error?.response?.data?.errors?.password}
              continue={t('RegisterPage.FailModalButton')}
            />
          )) : setFeedbackModal(<ErrorModal
            title={t('PasswordChangePage.FailModalTitle')}
            body={t('PasswordChangePage.FailModalText')}
            continue={t('PasswordChangePage.FailModalButton')}
          />)
      });
  };

  function redirectToLogin() {
    setGoToLogin(true);
  }

  useEffect(() => {
    if (goToLogin) {
      navigate(`/login`);
    }
  }, [goToLogin]);

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className=" w-auto" src={Logo_Vertical_SF} alt="Your Company" />
              </div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {t('PasswordChangePage.PageTitle')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6 w-full" onSubmit={handleSubmit}>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('PasswordChangePage.PasswordField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('PasswordChangePage.ConfirmPasswordField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        href=""
                        className="font-medium text-d-iteal hover:text-iteal"
                        onClick={redirectToLogin}
                      >
                        {t('PasswordChangePage.LoginLink')}
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-iteal focus:ring-offset-2"
                    >
                      {t('PasswordChangePage.SubmitButton')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={landing}
            alt=""
          />
        </div>
      </div>
      {feedbackModal}
    </>
  );
}

export default PasswordChange;
