import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCertificateData } from '../../../Requests/User/AppRequests'
import CompletionCertificate from './CompletionCertificate'

function CertificateLoader() {
    const [certificateData, setCertificateData] = useState(null)
    const { course_id } = useParams();

    useEffect(() => {
        course_id && getCertificateData(course_id).then((res) => {
            setCertificateData(res.data)
        });
    }, [course_id])
    return (
        <PDFViewer className='w-full h-full'>
            {certificateData && <CompletionCertificate userName={certificateData.User} courseName={'"' + certificateData.Course.name + '"'} courseDate={certificateData.Course.date} courseDuration={certificateData.Course.duration} courseTeacher={certificateData?.Course?.speakers[0]?.name}></CompletionCertificate>}
        </PDFViewer>
    )
}

export default CertificateLoader
