import React, { useEffect, useState } from 'react'
import { courseRegister, getCouses, getRegistrations, setCertificate } from '../../../Requests/User/AppRequests'
import { EnvelopeIcon, PhoneIcon, InboxArrowDownIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import Loader from '../../../Components/Loader'
import RegisterInCourseModal from '../../../Components/RegisterInCourseModal'
import SuccesModal from '../../../Components/SuccesModal'
import ZoomLinkModal from '../../../Components/ZoomLinkModal'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'

function simplifyTime(date) {
    let splitDate = date.split(":")
    if (splitDate.length > 1) {
        return "" + splitDate[0] + ":" + splitDate[1] + "h"
    }
}

function RegisterList({ fullBar }) {
    const [courses, setCourses] = useState(null)
    const [userRegistrations, setUserRegistrations] = useState(null)
    const [modal, setModal] = useState(null)
    const navigate = useNavigate();

    const fetchCourses = () => {
        let coursesRequest = getCouses(0)
        coursesRequest.then((res) => {
            setCourses(res.data.data)
        })
    }

    const fetchUserRegistrations = () => {
        let registrationsRequest = getRegistrations(0)
        registrationsRequest.then((res) => {
            setUserRegistrations(res.data)
        })
    }

    const closeModal = () => {
        setModal(null)
    }

    const showLink = (course, registration, link = "") => {
        setModal(<ZoomLinkModal title={course.name} body={link} continue="Fechar" close={closeModal} registration={registration}></ZoomLinkModal>)
    }

    const handleCertificate = (couse_id) => {
        window.open('/certificate/' + couse_id, '_blank', 'noreferrer')
    }

    const handleZoom = (course) => {
        let registration = userRegistrations?.find(courseIterator => courseIterator.course_id == course.id)
        showLink(course, registration, registration?.link)
    }

    useEffect(() => {
        fetchCourses()
        fetchUserRegistrations()
    }, [])



    return (
        <div className={fullBar ? 'md:pl-64 h-1/3 xl:h-3/12' : 'md:pl-16 h-1/3 xl:h-3/12'}>
            <div className='max-w-full h-full bg-gray-800'>

                <div className='w-full h-full flex flex-col'>
                    <div className='grow flex items-center justify-center text-3xl z-20'>
                        <div>
                            <div className='font-thin text-4xl sm:text-6xl text-center text-white'>Inscrições e </div>
                            <div className='mt-4 font-semibold text-4xl sm:text-6xl text-center text-iteal'>Certificados</div>

                        </div>
                    </div>
                </div>

            </div >

            <div className='flex flex-1 flex-col h-full mx-2 lg:mx-8 xl:mx-auto max-w-7xl'>
                <div className="border-b border-gray-200 pb-5 mt-12">
                    <h3 className="text-3xl font-medium leading-6 text-gray-900">Inscrições</h3>
                </div>

                <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid--2 lg:grid-cols-2 xl:grid-cols-3 py-8">
                    {!courses ? <Loader></Loader> : courses.map((course) => {
                        const userRegistration = userRegistrations?.find(courseIterator => courseIterator.course_id === course.id);
                        const questionair_complete = userRegistration?.questioniar_complete || null
                        if (userRegistration) {
                            return <li
                                key={course.id}
                                className={((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) ? " col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-200 text-center shadow" : "col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"}
                            >

                                <div
                                    className={((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) ? "contrast-50 flex flex-1 flex-col  bg-gray-200 text-center shadow" : "flex flex-1 flex-col  bg-white text-center shadow"}
                                >
                                    <img className="mx-0 object-cover w-full aspect-square" src={process.env.REACT_APP_DO_SPACES_URL + "/" + course.image} alt="" />
                                    <h3 className="mt-6 text-sm font-medium text-gray-900">{course.name}</h3>
                                    <dl className="mt-1 flex flex-grow flex-col justify-between p-8">
                                        <dt className="sr-only">Title</dt>
                                        <dd className="text-sm text-gray-500 text-justify">{course.description}</dd>
                                        <dt className="sr-only">Role</dt>
                                        <dd className="mt-3">

                                            <span className='text-sm'>Data: </span> <span className="rounded-full bg-l-iteal px-2 py-1 text-xs font-medium text-d-iteal">
                                                <span className={((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) && 'line-through'}>{course.date && simplifyTime(course.date)}</span>
                                            </span>
                                            {course.speakers.length > 0 &&
                                                <>
                                                    <dd className="mt-2">
                                                        <span className='text-sm'>Formadores: </span>
                                                    </dd>
                                                    <dd>
                                                        {course.speakers.map((speaker) => {
                                                            return (
                                                                <span className="rounded-full bg-l-iteal px-2 py-1 text-xs font-medium text-d-iteal">
                                                                    {speaker.name}
                                                                </span>

                                                            )
                                                        })}

                                                    </dd>
                                                </>
                                            }
                                        </dd>
                                    </dl>
                                </div>
                                <div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        {(((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) && course.recording_url) &&
                                            <div className="flex w-0 flex-1">
                                                <button
                                                    href={`mailto:${course.id}`}
                                                    className="bg-d-iteal relative -mr-px inline-flex w-0 flex-1 items-center justify-center  border border-transparent py-4 text-sm font-medium text-white hover:bg-white hover:text-zinc-900"
                                                    onClick={() => {
                                                        navigate("/video/" + course.id)
                                                    }}
                                                >
                                                    {!((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) ? <span className="ml-3  font-bold">Link Webinar</span> : <div>
                                                        <span>Ver Gravação</span>
                                                    </div>}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        <div className="flex w-0 flex-1">
                                            <button
                                                href={`mailto:${course.id}`}
                                                className={((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) ? "bg-iteal relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-sm font-medium text-white hover:bg-white hover:text-zinc-900" : "relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-white hover:bg-iteal"}
                                                onClick={() => {
                                                    if (!((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000))) {
                                                        (handleZoom(course))
                                                    } else {
                                                        if (userRegistration.hasQuestionair && questionair_complete == (true)) {
                                                            handleCertificate(course.id)
                                                        } else {
                                                            navigate("/questionair/" + course.id)
                                                        }
                                                    }

                                                }}
                                            >
                                                {!((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) ? <span className="ml-3  font-bold">Link Webinar</span> :
                                                    <div>
                                                        <span>{(userRegistration.hasQuestionair == false) ? "Certificado" : ((questionair_complete == true) ? "Certificado" : "Avaliação")}</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </li>
                        }

                    })}

                </ul>
                {(userRegistrations && userRegistrations.length) == 0 &&
                    <div className='w-full flex items-center justify-center flex-col'>
                        <InboxArrowDownIcon className='text-iteal w-8 h-8'></InboxArrowDownIcon>
                        <p className='text-xl  text-bold mt-4'>Ainda não tens inscrições!</p>
                        <p className='mt-2 text-base text-iteal'>Visita a nossa página de cursos para começar</p>
                        <button className=" px-6 py-2 flex-1 items-center justify-center rounded-lg border border-transparent text-base font-medium text-white bg-iteal mt-8" onClick={() => { navigate('/courses') }}>
                            <div className='flex items-center justify-center'>
                                <PlusCircleIcon className='w-4 h-4 mr-1'></PlusCircleIcon>
                                Cursos
                            </div>
                        </button>
                    </div>}
            </div>
            {modal}
        </div>
    )
}

export default RegisterList
