import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("token");
}

export function removeToken() {
  const cookies = new Cookies();
  return cookies.remove("token");
}

export function login(email, password) {
  var url = process.env.REACT_APP_API_BASE_URL + `/login`;
  return axios.post(url, {
    email: email,
    password: password,
  });
}

export function logout() {
  removeToken()
  var url = process.env.REACT_APP_API_BASE_URL + `/logout`;
  return axios.get(url);
}

export function register(name, email, password, password_confirmation, cp, location, nif, fun) {
  var url = process.env.REACT_APP_API_BASE_URL + `/register`;
  return axios.post(url, {
    name: name,
    email: email,
    password: password,
    password_confirmation: password_confirmation,
    cp: cp,
    location: location,
    nif: String(nif),
    fun: fun
  });
}

export function confirmEmail(confirmation_token) {
  var url = process.env.REACT_APP_API_BASE_URL + `/confirm_email`;
  return axios.post(url, {
    confirmation_token: confirmation_token,
  });
}


export function getAvailablePlans() {
  var url = process.env.REACT_APP_API_BASE_URL + "/plans";

  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function createNewSubscription(priceId) {
  var url = process.env.REACT_APP_API_BASE_URL + "/create_new_subscription";
  return axios.post(
    url,
    { priceId: priceId },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}


export function getSubscriptions() {
  var url = process.env.REACT_APP_API_BASE_URL + "/subscriptions";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function cancelSubscriptionRenewal(subscription_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/cancel_subscription_renewal";
  return axios.post(
    url,
    { subscription_id: subscription_id },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function reactivateSubscriptionRenewal(subscription_id) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/reactivate_subscription_renewal";
  return axios.post(
    url,
    { subscription_id: subscription_id },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function requestPasswordChange(email) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/request_password_change";
  return axios.post(
    url,
    {
      email: email
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function changePassword(confirmation_token, password, password_confirmation) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/change_password";
  return axios.post(
    url,
    {
      confirmation_token: confirmation_token, password: password,
      password_confirmation: password_confirmation
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function updatePassword(old_password, password, password_confirmation) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/setup_new_password";
  return axios.post(
    url,
    {
      old_password: old_password,
      password: password,
      password_confirmation: password_confirmation
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function updateEmailSettings(email_updates, email_reminders) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/update_email_settings";
  return axios.post(
    url,
    {
      email_updates: email_updates,
      email_reminders: email_reminders
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function getEmailSettings() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/email_settings";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function deleteUser() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/delete";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } }
  );
}