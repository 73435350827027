import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  BuildingLibraryIcon,
  RectangleStackIcon,
  XMarkIcon,
  Cog6ToothIcon,
  PowerIcon
} from "@heroicons/react/24/outline";
import logo from "../Assets/logo.png";
import UserSettings from "../Pages/Private/Settings/UserSettings";
import SendFeedbackModal from "./SendFeedbackModal";
import { logout } from "../Requests/User/UserRequests";
import { useTranslation } from "react-i18next";
import logo_horizontal from '../Assets/logo_horizontal.png'

const navigation = [
  { name: "Todos os Cursos", href: "/courses", icon: HomeIcon, current: false },
  { name: "Inscrições e Certificados", href: "/mycourses", icon: BuildingLibraryIcon, current: false },
  { name: "Definições", href: "/account_settings", icon: Cog6ToothIcon, current: false },
  { name: "Feedback", href: "#", icon: RectangleStackIcon, current: false },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function SideBar({ fullBar, handleSideBar }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(null);
  const { t } = useTranslation();

  const showFeedback = () => {
    setViewFeedback(<SendFeedbackModal key={Math.random()} />)
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <a href='www.aimphorma.com' className="flex flex-shrink-0 items-center px-4">
                    <div className="w-full flex justify-center">
                      <img
                        className="h-24 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                  </a>
                  <div className="text-center mt-5 space-y-1 px-2 text-white">
                    Academia imPHorma <span className="text-xs text-gray-200">Beta</span>
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(function (item) {
                      return item.name == "Feedback" ?
                        (<a
                          key={item.name}
                          onClick={showFeedback}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-iteal"
                                : "text-iteal group-hover:text-iteal",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                        :
                        (<a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-iteal"
                                : "text-iteal group-hover:text-iteal",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>)
                    }
                    )}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                  <a
                    href="/view_subscriptions"
                    className="group block flex-shrink-0"
                  >
                    <div className="flex items-center">
                      <a
                        onClick={() => {
                          logout()
                        }}
                        href="/login"
                        className="group block w-full flex-shrink-0 text-white text-sm"
                      >Logout</a>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className={fullBar ? "hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col" : "hidden md:fixed md:inset-y-0 md:flex md:w-16 md:flex-col"} onMouseEnter={() => { handleSideBar(true) }} onMouseLeave={() => { handleSideBar(false) }}>
        <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center">
              <a href="/" target="_blank" className="w-full flex justify-center">
                <img className={fullBar ? "h-24 w-auto" : "h-12 w-auto"} src={logo} alt="Your Company" />
              </a>
            </div>
            <div className={fullBar ? "text-center text-xl mt-5 space-y-1 px-2 text-white" : "text-center text-xl mt-5 space-y-1 px-2 text-white mb-16"}>
              {fullBar && <><span> Academia imPHorma</span> <span className="text-xs text-gray-200">Beta</span></>}
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
              {navigation.map(function (item) {
                return item.name == "Feedback" ?
                  (<a
                    key={item.name}
                    onClick={showFeedback}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      !fullBar ? "justify-center" : "mt-4"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-iteal"
                          : "text-iteal group-hover:text-iteal",
                        fullBar ? "mr-3 flex-shrink-0 h-6 w-6" : "flex-shrink-0 h-8 w-8"
                      )}
                      aria-hidden="true"
                    />
                    {fullBar && item.name}
                  </a>)
                  :
                  (<a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      !fullBar ? "justify-center" : "mt-4"
                    )}
                  >
                    <div className={!fullBar && "flex justify-center"}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-iteal"
                            : "text-iteal group-hover:text-iteal",
                          fullBar ? "mr-3 flex-shrink-0 h-6 w-6" : "flex-shrink-0 h-8 w-8"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    {fullBar && item.name}
                  </a>
                  )
              })}
            </nav>
          </div>
          <div className="flex flex-shrink-0 bg-gray-700 p-4">
            <a
              onClick={() => {
                logout()
              }}
              href="/login"
              className="group block w-full flex-shrink-0"
            >
              <div className="flex items-center">
                {fullBar && <div className="flex items-center justify-center">
                  <PowerIcon className="text-iteal flex-shrink-0 h-6 w-6"></PowerIcon>
                  <p className="ml-4 text-xs font-medium text-gray-300 group-hover:text-gray-200">
                    {t('Sidebar.LogoutText')}
                  </p>
                </div>
                }
                {!fullBar && <PowerIcon className="text-iteal flex-shrink-0 h-8 w-8"></PowerIcon>}
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="sticky top-0 z-10 bg-gray-800 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden flex space-between ">
        <div className="w-full flex items-center">
          <img
            className="h-12 w-auto p-2"
            src={logo}
            alt="Your Company"
          />
          <div className="grow text-center  space-y-1 px-2 text-white flex items-center justify-center">
            Academia imPHorma
          </div>
        </div>
        <button
          type="button"
          className=" aspect-square inline-flex h-12 items-center justify-center rounded-md text-white hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500  mr-2"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>

          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      {viewFeedback}
    </div>
  );
}

export default SideBar;
