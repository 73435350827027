import React from 'react';

export default function MultipleChoiceQuestion({ question, setAnswers }) {
    // This function is called whenever an option is selected
    const handleSelection = (selectedOption) => {
        setAnswers((prevAnswers) => {
            // First, remove any previous answer for this question to avoid duplicates
            const updatedAnswers = prevAnswers.filter(ans => ans.id !== question.id);

            // Then, add the new answer for this question
            updatedAnswers.push({ id: question.id, answer: selectedOption });

            // Return the updated answers array
            return updatedAnswers;
        });
    };

    return (
        <div className='px-4 py-4'>
            <div>{question.question}</div>
            <div>
                <fieldset className="mt-4">
                    <div className="space-y-4">
                        {/* Dynamically generating radio buttons for options A to D */}
                        {['A', 'B', 'C', 'D', 'E', 'F'].map((option) => (
                            question[option] &&
                            <div key={option} className="flex items-center">
                                <input
                                    id={`${question.id}-${option}`}
                                    name={`notification-method-${question.id}`}
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-iteal focus:ring-iteal"
                                    onChange={() => handleSelection(option)} />
                                <label htmlFor={`${question.id}-${option}`} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                    {question[option]}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </div>
        </div>
    );
}
