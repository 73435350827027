import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

function simplifyTime(date) {
  let splitDate = date.split(":")
  if (splitDate.length > 1) {
    return "" + splitDate[0] + ":" + splitDate[1] + "h"
  }
}

export default function RegisterInCourseModal(props) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [payWithTransfer, setPayWithTransfer] = useState(false)

  const handleClick = () => {
    if (props.callback) {
      props.callback(props.course)
    } else { setOpen(false) };
    setIsLoading(true);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {
        props.close();
        setOpen(false);
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full  sm:p-6 max-w-xl">
                <div>

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {props.course && "Registar no curso " + props.course.name + "?"}
                    </Dialog.Title>
                    {props.course && <img className="mx-0 my-4 object-cover w-full aspect-square" src={process.env.REACT_APP_DO_SPACES_URL + "/" + props.course.image} alt="" />}
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {props.course && props.course.description}
                      </p>
                    </div>
                    <div className="mt-6">
                      <span className='text-sm'>Data: </span> <span className=" rounded-full bg-d-iteal px-2 py-1 text-sm font-medium  text-white">
                        {props.course.date && simplifyTime(props.course.date)}
                      </span>
                      {props.course.price && <dd className="mt-3">
                        <span className='text-sm'>Preço: </span> <span className="rounded-full bg-d-iteal px-2 py-1 text-xs font-medium text-white">
                          {props.course.price.data[0].unit_amount / 100}€
                        </span>

                      </dd>
                      }
                      {props.course.speakers.length > 0 &&
                        <>
                          <dd className="mt-2">
                            <span className='text-sm'>Formadores: </span>
                          </dd>
                          <dd>
                            {props.course.speakers.map((speaker) => {
                              return (
                                <span className="rounded-full bg-d-iteal px-2 py-1 text-xs font-medium text-white">
                                  {speaker.name}
                                </span>

                              )
                            })}

                          </dd>
                        </>
                      }
                    </div>
                  </div>

                </div>

                {(props.continue && payWithTransfer == false) && <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-iteal px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-iteal focus:ring-offset-2 sm:text-sm"
                    onClick={handleClick}
                  >
                    {isLoading ? <Loader></Loader> : props.continue}
                  </button>
                </div>}
                {payWithTransfer &&
                  <div className="mt-4  flex flex-col items-center justify-center mx-8 ">
                    <div className="text-sm mt-2">
                      Para pagar por transferência, realiza a transferência para:
                    </div>
                    <div className=" text-md text-iteal mt-2">
                      LT11 3250 0844 1824 6271
                    </div>

                    <div className="text-sm mt-2">
                      E envia o comprovativo para geral@aimphorma.com e iremos activar o teu curso!
                    </div>
                  </div>
                }
                {props.course.price && (payWithTransfer ? <button className="w-full mt-4 flex justify-center text-sm text-iteal" onClick={() => { setPayWithTransfer(false) }}>Pagar com cartão</button> : <button className="w-full mt-4 flex justify-center text-sm text-iteal" onClick={() => { setPayWithTransfer(true) }}>Preferes fazer transferência? Carrega aqui</button>)}

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
