
import logo from "./logo.svg";
import "./App.css";
import Login from "./Pages/Public/Login";
import Register from "./Pages/Public/Register";
import Cookies from "universal-cookie";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import Dashboard from "./Pages/Private/Dashboard";
import Landing from "./Pages/Public/Landing";
import ChoosePlans from "./Pages/Private/Settings/Payments/ChoosePlans";
import ViewPlans from "./Pages/Private/Settings/Payments/ViewPlans";
import SideBar from "./Components/SideBar";
import UserSettings from "./Pages/Private/Settings/UserSettings";
import BillingController from "./Pages/Private/Settings/Payments/BillingController";
import ViewSubscriptions from "./Pages/Private/Settings/Payments/ViewSubscriptions";
import EmailConfirmation from "./Pages/Public/EmailConfirmation";
import RequestPasswordChange from "./Pages/Public/RequestPasswordChange";
import PasswordChange from "./Pages/Public/PasswordChange";
import CookieConsent from "react-cookie-consent";
import AccountSettings from "./Pages/Private/Settings/Account/AccountSettings";
import NotFound from "./Pages/Public/NotFound";
import CourseList from "./Pages/Private/Courses/CourseList";
import RegisterList from "./Pages/Private/Courses/RegisterList";
import CompletionCertificate from "./Pages/Private/Courses/CompletionCertificate";
import CertificateLoader from "./Pages/Private/Courses/CertificateLoader";
import { useState } from "react";
import VideoViewer from "./Pages/Private/Videos/VideoViewer";
import Statistics from "./Pages/Public/Statistics";
import Questionair from "./Pages/Private/Questionair/Questionair";

function PrivateOutlet({ children }) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return !!token ? children : <Navigate to="/login" />;
}

function PublicOutlet({ children }) {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return !!token ? <Navigate to="/courses" /> : children;
}

function CookieWarning() {
  const cookies = new Cookies();
  const cookieConsent = cookies.get("cookieConsent");
  if (!cookieConsent) {
    return <CookieConsent
      location="bottom"
      buttonText="Aceitar e fechar"
      cookieName="Consent_cookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: "#53b3b3", color: "white", fontSize: "13px" }}
    >
      Este website usa cookies para o seu funcionamento.{" "} Ao utilizar este website concordas e aceitas a <a href='/PP.pdf' download className="text-iteal">Política de Privacidade</a> e os  <a href='/T&C.pdf' download className="text-iteal">Termos e Condições</a>
    </CookieConsent>
  }
}

function App() {
  const [fullBar, setFullBar] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get("token");
  const handleSideBar = (status) => {
    setFullBar(status)
  }
  return (
    <BrowserRouter>
      <CookieWarning />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<PublicOutlet><Login /></PublicOutlet>} />
        <Route path="/register" element={<PublicOutlet><Register /></PublicOutlet>} />
        <Route path="/email_confirmation" element={<EmailConfirmation />} />
        <Route path="/request_password_change" element={<RequestPasswordChange />} />
        <Route path="/change_password" element={<PasswordChange />} />
        <Route path="/certificate/:course_id" element={<CertificateLoader course_id={14} />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route
          path="/courses"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <CourseList fullBar={fullBar} />
            </PrivateOutlet>
          }
        />
        <Route
          path="/questionair/:course_id"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <Questionair fullBar={fullBar} />
            </PrivateOutlet>
          }
        />
        <Route
          path="/mycourses"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <RegisterList fullBar={fullBar} />
            </PrivateOutlet>
          }
        />
        {/*Settings */}
        {/*   Account */}
        <Route
          path="/account_settings"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <UserSettings tabName="Account" fullBar={fullBar}>
                <AccountSettings />
              </UserSettings>
            </PrivateOutlet>
          }
        />
        {/*   Billing*/}
        <Route
          path="/empty_subscriptions"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <UserSettings tabName="Plans" fullBar={fullBar}>
                <BillingController />
              </UserSettings>
            </PrivateOutlet>
          }
        />
        <Route
          path="/view_subscriptions"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <UserSettings tabName="Plans" fullBar={fullBar}>
                <BillingController>
                  <ViewSubscriptions />
                </BillingController>
              </UserSettings>
            </PrivateOutlet>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <UserSettings tabName="Plans" fullBar={fullBar}>
                <BillingController>
                  <ViewPlans />
                </BillingController>
              </UserSettings>
            </PrivateOutlet>
          }
        />
        <Route
          path="/payment_option_details"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <UserSettings tabName="Plans" fullBar={fullBar}>
                <BillingController forceRefresh={true}>
                  <ChoosePlans />
                </BillingController>
              </UserSettings>
            </PrivateOutlet>
          }
        />
        <Route
          path="/video/:course_id"
          element={
            <PrivateOutlet>
              <SideBar fullBar={fullBar} handleSideBar={handleSideBar} />
              <VideoViewer fullBar={fullBar} />
            </PrivateOutlet>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

    </BrowserRouter>
  );
}
export default App;
