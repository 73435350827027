import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import { getCourse } from '../../../Requests/User/AppRequests';

function VideoViewer({ fullBar }) {

    let { course_id } = useParams();
    const navigate = useNavigate();
    const [videoPlayer, setVideoPlayer] = useState(null)
    const [course, setCourse] = useState()
    const [currentVideo, setCurrentVideo] = useState(null)

    useEffect(() => {
        if (!course_id) {
            navigate("/")
        } else {
            getCourse(course_id).then((res) => {
                if (!(res.data.Course && res.data.Registration)) {
                    navigate("/")
                } else {
                    setCourse(res.data.Course)
                    setCurrentVideo(res.data.Course.recording_url)
                }
            })
        }
    }, [])

    useEffect(() => {
        setVideoPlayer(<div data-dyntube-key={currentVideo} className="sm:mx-4"></div>)
    }, [currentVideo])

    const handleChangePart = (module) => {
        setVideoPlayer(null)
        setCurrentVideo(module)
    }


    useEffect(() => {
        const handlePlayerReady = (event) => {
            var player = event.player;
            player.on('ended', function () {
                // Use a function to access the current value of currentVideo dynamically
                setCurrentVideo((currentVideo) => {
                    var modulesArray = course?.modules?.split(",");
                    var find_index = modulesArray.indexOf(currentVideo); // Find the index of currentVideo in the array

                    if (find_index == -1 && modulesArray.length > 0 && course?.modules != "") {
                        handleChangePart(modulesArray[0])
                    }
                    else if (find_index == -1) {
                        navigate("/questionair/" + course_id)
                    }
                    else if (find_index >= 0 && find_index < (modulesArray.length - 1)) {
                        handleChangePart(modulesArray[find_index + 1])
                    } else {
                        navigate("/questionair/" + course_id)
                    }
                });
            });
        };

        // Add event listener when the component mounts or currentVideo changes
        window.addEventListener("dyntubePlayerReady", handlePlayerReady);

        // Cleanup the event listener on component unmount or when currentVideo changes
        return () => {
            window.removeEventListener("dyntubePlayerReady", handlePlayerReady);
        };
    }, [currentVideo]); // Re-run useEffect when currentVideo changes



    return (
        <div className={fullBar ? 'md:pl-64 h-1/3 xl:h-3/12' : 'md:pl-16 h-1/3 xl:h-3/12'}>
            {(videoPlayer && course) ?
                <div className='max-w-7xl mx-auto mt-12'>
                    <div className='text-2xl font-bold text-d-iteal mb-8 mx-4'>{course.name}</div>
                    {videoPlayer}

                    {course.modules && <div className='flex justify-start items-center gap-4 px-4 py-4'>
                        <button className='text-sm text-white bg-d-iteal rounded-md px-4 py-2'
                            onClick={() => handleChangePart(course.recording_url)}
                        >
                            Parte 1
                        </button>
                        {course.modules?.split(",").map(
                            (module, index) => {
                                return (
                                    <button className='text-sm text-white bg-d-iteal rounded-md px-4 py-2'
                                        onClick={() => handleChangePart(module)}
                                    >
                                        Parte {index + 2}
                                    </button>)
                            }
                        )}
                    </div>
                    }
                    <div className='text-lg text-gray-400 mt-8 mx-4'>Formadora:</div>
                    <div className='flex text-base w-fit font-bold bg-d-iteal text-white rounded-2xl mt-6 mx-4 px-4'>{course?.speakers[0]?.name}</div>
                    <div className='text-lg text-gray-400 mt-8 mx-4'>Sobre este curso:</div>
                    <div className='text-base text-gray-600 mt-4 mx-4'>{course.description}</div>
                </div>
                :
                <div className='w-full h-full flex items-center justify-center '>
                    <Loader></Loader>
                </div>
            }
        </div>
    );
}

export default VideoViewer
