import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorModal from '../../../../Components/ErrorModal';
import SuccesModal from '../../../../Components/SuccesModal';
import DeleteAccountModal from '../../../../Components/DeleteAccountModal';
import { deleteUser, getEmailSettings, updateEmailSettings, updatePassword } from '../../../../Requests/User/UserRequests';

function AccountSettings() {
    const { t } = useTranslation();
    const [newPasswordIsLoading, setNewPasswordIsLoading] = useState(false)
    const [updateEmailSettingsLoading, setUpdateEmailSettingsLoading] = useState(false)
    const [feedbackModal, setFeedbackModal] = useState(null)
    const [emailSettings, setEmailSettings] = useState(null)

    let handleCancelUser = async (event) => {
        let reset = await setFeedbackModal(null)
        event.preventDefault();
        setFeedbackModal(<DeleteAccountModal
            title={t('AccountSettingsPage.DeleteModalTitle')}
            body={t('AccountSettingsPage.DeleteModalText')}
            continue={t('AccountSettingsPage.DeleteModalButton')}
            navLink="/login"
            fun={deleteUser}
        ></DeleteAccountModal>)
    }

    let handleChangePassword = (event) => {
        event.preventDefault();
        setFeedbackModal(null)
        setNewPasswordIsLoading(true)
        let response = updatePassword(
            event.target.current_password.value,
            event.target.password.value,
            event.target.password_confirmation.value
        );
        response.
            then((res) => {
                setNewPasswordIsLoading(false);
                setFeedbackModal(
                    <SuccesModal
                        title={t('AccountSettingsPage.PasswordSuccessModalTitle')}
                        body={t('AccountSettingsPage.PasswordSuccessModalText')}
                        continue={t('AccountSettingsPage.PasswordSuccessModalButton')}
                    />
                );
            })
            .catch((error) => {
                setNewPasswordIsLoading(false);
                setFeedbackModal(<ErrorModal
                    title={t('AccountSettingsPage.PasswordFailModalTitle')}
                    body={t('AccountSettingsPage.PasswordFailModalText')}
                    continue={t('AccountSettingsPage.PasswordFailModalButton')}
                />
                )
            })
    }

    let handleChangeEmailSettings = (event) => {
        event.preventDefault();
        setFeedbackModal(null)
        setUpdateEmailSettingsLoading(true)
        let response = updateEmailSettings(
            event.target.email_updates.checked,
            event.target.email_reminders.checked
        );
        response.
            then((res) => {
                setUpdateEmailSettingsLoading(false);
                setFeedbackModal(
                    <SuccesModal
                        title={t('AccountSettingsPage.EmailSuccessModalTitle')}
                        body={t('AccountSettingsPage.EmailSuccessModalText')}
                        continue={t('AccountSettingsPage.EmailSuccessModalButton')}
                    />
                );
            })
            .catch((error) => {
                setUpdateEmailSettingsLoading(false);
                setFeedbackModal(<ErrorModal
                    title={t('AccountSettingsPage.EmailFailModalTitle')}
                    body={t('AccountSettingsPage.EmailFailModalText')}
                    continue={t('AccountSettingsPage.EmailFailModalButton')}
                />
                )
            })
    }


    let fetchEmailSettings = () => {
        let response = getEmailSettings()
        response.then((res) => {
            setEmailSettings(res.data)
        })
    }

    useEffect(() => {
        fetchEmailSettings()
    }, [])

    return (
        <div className="text-start h-full flex flex-1 flex-col justify-center bg-white ">
            <div className='mx-2 mt-2 sm:mx-8 sm:mt-8'>
                <div className="mb-16 space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <form className="px-2 space-y-8  divide-gray-200 sm:px-8" onSubmit={handleChangePassword}>
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('AccountSettingsPage.PasswordSettingTitle')}</h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="current_password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t('AccountSettingsPage.PasswordSettingCurrentPassword')}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="password"
                                            name="current_password"
                                            id="current_password"
                                            autoComplete="given-name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-iteal focus:ring-iteal sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t('AccountSettingsPage.PasswordSettingNewPassword')}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            autoComplete="family-name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-iteal focus:ring-iteal sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        {t('AccountSettingsPage.PasswordSettingRepeatPassword')}
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="password"
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            autoComplete="family-name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-iteal focus:ring-iteal sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-5">
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-iteal focus:ring-offset-2"
                                >
                                    {t('AccountSettingsPage.PasswordSettingAction')}
                                </button>
                            </div>
                        </div>
                    </form>

                    <form className="px-2 space-y-8  divide-gray-200 sm:px-8" onSubmit={handleChangeEmailSettings}>
                        <div className="space-y-6 divide-y divide-gray-200 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900"> {t('AccountSettingsPage.EmailSettingTitle')}</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    {t('AccountSettingsPage.EmailSettingDescription')}
                                </p>
                            </div>
                            <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">
                                <div className="pt-6 sm:pt-5">
                                    <div role="group" aria-labelledby="label-email">
                                        <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                                            <div>
                                                <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">

                                                    {t('AccountSettingsPage.EmailType')}
                                                </div>
                                            </div>
                                            <div className="mt-4 sm:col-span-2 sm:mt-0">
                                                <div className="max-w-lg space-y-4">
                                                    <div className="relative flex items-start">
                                                        <div className="flex h-5 items-center">
                                                            <input
                                                                id="email_updates"
                                                                name="email_updates"
                                                                type="checkbox"
                                                                defaultChecked={emailSettings?.email_updates}
                                                                className="h-4 w-4 rounded border-gray-300 text-iteal focus:ring-iteal"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor="email_updates" className="font-medium text-gray-700">
                                                                {t('AccountSettingsPage.EmailUpdatesTitle')}
                                                            </label>
                                                            <p className="text-gray-500">{t('AccountSettingsPage.EmailUpdatesText')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex h-5 items-center">
                                                            <input
                                                                id="email_reminders"
                                                                name="email_reminders"
                                                                type="checkbox"
                                                                defaultChecked={emailSettings?.email_reminders}
                                                                className="h-4 w-4 rounded border-gray-300 text-iteal focus:ring-iteal"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor="email_reminders" className="font-medium text-gray-700">
                                                                {t('AccountSettingsPage.EmailPromotionsTitle')}
                                                            </label>
                                                            <p className="text-gray-500">{t('AccountSettingsPage.EmailPromotionsText')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-5">
                            <div className="flex justify-end">

                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-iteal focus:ring-offset-2"
                                >
                                    {t('AccountSettingsPage.EmailPromotionsAction')}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="space-y-6  divide-gray-200 pt-8 sm:space-y-5 sm:pt-10 px-2 sm:px-8">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('AccountSettingsPage.DeleteAccountTitle')}</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {t('AccountSettingsPage.DeleteAccountText')}
                            </p>
                        </div>
                        <div className="space-y-6  divide-gray-200 sm:space-y-5">
                            <div className="pt-6 sm:pt-5">
                                <div>
                                    <a
                                        href="/login"
                                        onClick={
                                            handleCancelUser
                                        }
                                        type="button"
                                        className="rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2  sm:w-auto sm:text-sm"
                                    >
                                        {t('AccountSettingsPage.DeleteAccountAction')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="w-full text-sm text-gray-500 my-6 mx-4 text-end" onClick={() => window.location = 'mailto:geral@aimphorma.com'}>Estás com problemas? Carrega <span className='text-iteal'>aqui</span> nos enviar um email</button>

            </div >
            {feedbackModal}
        </div >
    )
}

export default AccountSettings