import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function getQuestions(course_id) {
    var url = process.env.REACT_APP_API_BASE_URL + "/questionair/" + course_id;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function submitQuestionair(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/evaluate_questionair";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}
