import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import StripeCardPage from "./StripeCardPage";
import "./ChoosePlan.css";
import { useParams, useSearchParams } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY
);

export default function ChoosePlans() {
  let { payment_intent_client_secret } = useParams();
  const [clientSecret, setClientSecret] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const removeQueryParams = () => {
    const client_secret_param = searchParams.get(
      "payment_intent_client_secret"
    );
    client_secret_param && searchParams.delete("payment_intent_client_secret");

    if (client_secret_param) {
      setSearchParams(searchParams);
      setClientSecret(client_secret_param);
    }
  };

  useEffect(() => {
    removeQueryParams();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        padding: "25px",
      }}
    >
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <StripeCardPage secret={clientSecret} />
        </Elements>
      )}

    </div>
  );
}
