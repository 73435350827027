import React, { useEffect, useState } from 'react'
import { courseRegister, getCouses, getRegistrations } from '../../../Requests/User/AppRequests'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import Loader from '../../../Components/Loader'
import RegisterInCourseModal from '../../../Components/RegisterInCourseModal'
import SuccesModal from '../../../Components/SuccesModal'
import { courseCheckout } from '../../../Requests/User/BillingRequests'
import ZoomLinkModal from '../../../Components/ZoomLinkModal'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'

function simplifyTime(date) {
    let splitDate = date.split(":")
    if (splitDate.length > 1) {
        return "" + splitDate[0] + ":" + splitDate[1] + "h"
    }
}

function CourseList({ fullBar }) {
    const [courses, setCourses] = useState(null)
    const [userRegistrations, setUserRegistrations] = useState(null)
    const [modal, setModal] = useState(null)
    const navigate = useNavigate();

    const fetchCourses = () => {
        let coursesRequest = getCouses(0)
        coursesRequest.then((res) => {
            setCourses(res.data.data)
        })
    }

    const fetchUserRegistrations = () => {
        let registrationsRequest = getRegistrations(0)
        registrationsRequest.then((res) => {
            setUserRegistrations(res.data)
        })
    }

    const closeModal = () => {
        setModal(null)
    }

    const registerAndCloseModal = (course) => {
        if (course.price) {
            courseCheckout(course.id).then((res) => {
                res?.data?.url && window.open(res.data["url"], "_self");
            })
        } else {
            courseRegister(course.id)
            closeModal()
            fetchUserRegistrations()
            setModal(<SuccesModal title="Registado" body="Para veres os teus registos vai a Inscrições e Certificados" continue="Fechar" close={closeModal}></SuccesModal>)
        }
    }

    const registerInCourse = (course) => {
        setModal(<RegisterInCourseModal course={course} continue={course.price ? "Comprar e Registar" : "Registar"} callback={registerAndCloseModal} close={closeModal}></RegisterInCourseModal>)
    }

    const handleShowZoom = (course) => {
        let registration = userRegistrations?.find(courseIterator => courseIterator.course_id == course.id)
        setModal(<ZoomLinkModal title={course.name} body={registration.link} continue="Fechar" close={closeModal} registration={registration}></ZoomLinkModal>)

    }


    useEffect(() => {
        fetchCourses()
        fetchUserRegistrations()
    }, [])

    return (

        <div className={fullBar ? 'md:pl-64 h-1/3 xl:h-3/12' : 'md:pl-16 h-1/3 xl:h-3/12'}>
            <div className='max-w-full h-full bg-gray-800'>

                <div className='w-full h-full flex flex-col'>
                    <div className='grow flex items-center justify-center text-3xl z-20'>
                        <div>
                            <div className='mx-4 font-thin text-3xl sm:text-6xl text-center text-white'>Bem-Vindo à <span className='text-iteal font-normal'>Academia imPHorma!</span></div>
                            <div className='mx-4 mt-4 font-thin text-3xl sm:text-6xl text-center text-white'>Vê os próximos cursos online</div>

                        </div>
                    </div>
                </div>

            </div >
            <div className='flex flex-1 flex-col h-full mx-2 lg:mx-8 xl:mx-auto max-w-7xl'>
                <div className="border-b border-gray-200 pb-5 mt-12">
                    <h3 className="text-3xl font-medium leading-6 text-gray-900">Os nossos cursos</h3>
                </div>
                <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 py-8">
                    {!courses ? <Loader></Loader> : courses.map((course) => (
                        <li
                            key={course.id}
                            className={((new Date()).getTime() > new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')) + course.duration * 60 * 60 * 1000 && !course.recording_url) ? "contrast-50 col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-200 text-center shadow" : "col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"}
                        >
                            <div className="flex flex-1 flex-col">
                                <img className="mx-0 object-cover w-full aspect-square" src={process.env.REACT_APP_DO_SPACES_URL + "/" + course.image} alt="" />
                                <h3 className="mt-6 text-sm font-medium text-gray-900">{course.name}</h3>
                                <dl className="mt-1 flex flex-grow flex-col justify-between p-8">
                                    <dt className="sr-only">Title</dt>
                                    <dd className="text-sm text-gray-500 text-justify">{course.description}</dd>
                                    <dt className="sr-only">Role</dt>
                                    <dd className="mt-3">

                                        <span className='text-sm'>Data: </span> <span className={
                                            !((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000))

                                                ? "rounded-full bg-l-iteal px-2 py-1 text-xs font-medium text-d-iteal" : "rounded-full bg-l-iteal px-2 py-1 text-xs font-medium text-d-iteal line-through"}>
                                            {course.date && simplifyTime(course.date)}

                                        </span>
                                    </dd>

                                    {course.speakers.length > 0 &&
                                        <>
                                            <dd className="mt-2">
                                                <span className='text-sm'>Formadores: </span>
                                            </dd>
                                            <dd>
                                                {course.speakers.map((speaker) => {
                                                    return (
                                                        <span className="rounded-full bg-l-iteal px-2 py-1 text-xs font-medium text-d-iteal">
                                                            {speaker.name}
                                                        </span>

                                                    )
                                                })}

                                            </dd>
                                        </>
                                    }
                                    {course.price && <dd className="mt-3">
                                        <span className='text-sm'>Preço: </span> <span className="rounded-full bg-l-iteal px-2 py-1 text-xs font-medium text-d-iteal">
                                            {course.price.data[0].unit_amount / 100}€
                                        </span>

                                    </dd>
                                    }
                                </dl>
                            </div>
                            <div>

                                {!((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000)) &&
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        <div className="flex w-0 flex-1">
                                            <button
                                                href={`mailto:${course.id}`}
                                                className={!userRegistrations?.find(courseIterator => courseIterator.course_id == course.id) ? "bg-iteal rounded-b-md relative -mr-px inline-flex w-0 flex-1 items-center justify-center  border border-transparent py-4 text-sm font-medium text-gray-700 hover:bg-d-iteal" : "bg-gray-200 rounded-b-md relative -mr-px inline-flex w-0 flex-1 items-center justify-center  border border-transparent py-4 text-sm font-medium text-gray-700"}
                                                onClick={() => {
                                                    if (!userRegistrations?.find(courseIterator => courseIterator.course_id == course.id)) {
                                                        //courseRegister(course.id)
                                                        registerInCourse(course)
                                                        fetchUserRegistrations()
                                                    } else {
                                                        handleShowZoom(course)
                                                    }
                                                }}
                                            >
                                                {
                                                    userRegistrations?.find(courseIterator => courseIterator.course_id == course.id) ?
                                                        <span className="ml-3 text-iteal ">Link Webinar</span> :
                                                        <span className="ml-3 text-white">Registar agora</span>
                                                }
                                            </button>
                                        </div>

                                    </div>}
                                {
                                    ((new Date()).getTime() > new Date(((new Date((moment(course.date)).format('YYYY-MM-DDTHH:mm:ss')))).getTime() + course.duration * 60 * 60 * 1000))
                                    && (
                                        course.recording_url ?
                                            <div className="-mt-px flex divide-x divide-gray-200">
                                                <div className="flex w-0 flex-1">
                                                    <button
                                                        href={`mailto:${course.id}`}
                                                        className="bg-d-iteal rounded-b-md relative -mr-px inline-flex w-0 flex-1 items-center justify-center  border border-transparent py-4 text-sm font-medium text-white hover:bg-iteal hover:text-zinc-900"
                                                        onClick={() => {
                                                            if (userRegistrations?.find(courseIterator => courseIterator.course_id == course.id)) {
                                                                navigate("/video/" + course.id)
                                                            } else {
                                                                registerInCourse(course)
                                                                fetchUserRegistrations()
                                                            }
                                                        }}

                                                    >

                                                        <span className="ml-3 text-white font-bold">Ver Gravação</span>

                                                    </button>
                                                </div>


                                            </div> :
                                            <div className="-mt-px flex divide-x divide-gray-200">
                                                <div className="flex w-0 flex-1">
                                                    <button
                                                        href={`mailto:${course.id}`}
                                                        className="bg-gray-300 rounded-b-md relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-sm font-medium text-gray-700"
                                                        disabled

                                                    >
                                                        <span className="ml-3 text-iteal font-bold">Curso passado</span>
                                                    </button>
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {modal}
        </div>
    )
}

export default CourseList
