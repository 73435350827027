import React, { useEffect, useState } from 'react'
import { get_all_user_stats, get_users_stats_by_course } from '../../Requests/User/AppRequests'

function Statistics() {
    const [results, setResults] = useState(null)
    const [stats, setStats] = useState(null)

    useEffect(() => {
        if (results) {
            const groups = [];

            results.forEach(result => {
                const fun = result.fun;

                if (fun !== null) {
                    const existingGroup = groups.find(group => group.key === fun);

                    if (existingGroup) {
                        existingGroup.values.push(result);
                    } else {
                        groups.push({ key: fun, values: [result] });
                    }
                }
            });

            setStats(groups);
        }
    }, [results])


    const handleSubmit = (event) => {
        event.preventDefault()
        if (event.target.course_name.value) {
            get_users_stats_by_course(event.target.api_key.value, event.target.course_name.value).then((res) => {
                setResults(res.data)
            })
        } else {
            get_all_user_stats(event.target.api_key.value).then((res) => {
                setResults(res.data)
            })
        }
    }

    return (
        <div className='p-8 '>
            <p className='text-3xl mb-8'>Estatísticas - Área reservada</p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="api_key" className="block text-sm font-medium leading-6 text-gray-900">
                    Chave
                </label>
                <div className="mt-2">
                    <input
                        type="api_key"
                        name="api_key"
                        id="api_key"
                        className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                    />
                </div>
                <label htmlFor="course_name" className="block text-sm font-medium leading-6 text-gray-900">
                    Nome do curso
                </label>
                <div className="mt-2">
                    <input
                        type="course_name"
                        name="course_name"
                        id="course_name"
                        className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                    />
                </div>
                <div className='py-4 w-full flex justify-end '>
                    <button className='px-4 py-2 bg-iteal text-white rounded-md'>Ver dados</button>
                </div>
            </form>

            {results &&

                <div className="px-2">
                    <p className='text-2xl'>Resultados</p>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                Profissão
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Contagem
                                            </th>

                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {stats?.map((stat) => (
                                            <>
                                                <tr key={stat.key}>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{stat.key}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{stat.values.length}</td>
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default Statistics
