import React, { useState } from "react";
import BillingController from "./Payments/BillingController";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UserSettings({ fullBar, ...children }) {
  const [mountedSettings, setMoutedSetting] = useState(children.children);
  const { t } = useTranslation();
  const [mountedSettingsName, setMoutedSettingName] = useState(
    children.tabName
  );
  return (

    <div className="h-full">
      <div className={fullBar ? "flex flex-1 flex-col md:pl-64 h-full" : "flex flex-1 flex-col sm:pl-16 h-full"}>
        <main className="flex-1 h-full">
          <div className="py-6  h-full">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  h-full">
              <div className="border-b border-gray-200 pb-5 sm:pb-0 ">
                <div className='w-full h-full flex flex-col'>
                  <div className='w-full'>
                    <div className="border-b border-gray-200 pb-5 mt-12">
                      <h3 className="text-3xl font-medium leading-6 text-gray-900 w-full">Definições</h3>
                    </div>

                  </div>
                </div>

                {mountedSettings}
              </div>
            </div>
            <div className="mx-0 w-full px-4 sm:px-6 md:px-8 mt-10"></div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default UserSettings;
