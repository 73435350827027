import React from 'react'
import registerimage from "../../Assets/human1.png";
import logo_horizontal from "../../Assets/logo_horizontal.png";
export default function NotFound() {
    return (
        <>
            {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
            <div className="flex min-h-full flex-col bg-white lg:relative">
                <div className="flex flex-grow flex-col">
                    <main className="flex flex-grow flex-col bg-white">
                        <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
                            <div className="flex-shrink-0 pt-10 sm:pt-16">
                                <a href="/" className="inline-flex">
                                    <img
                                        className="h-32 w-auto" src={logo_horizontal}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className="my-auto flex-shrink-0 py-16 sm:py-32">
                                <p className="text-base font-semibold text-iteal">404</p>
                                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Página inexistente</h1>
                                <p className="mt-2 text-base text-gray-500">Pedimos desculpa, mas não encontramos a página que procuras.</p>
                                <div className="mt-6">
                                    <a href="/login" className="text-base font-medium text-iteal hover:text-iteal">
                                        Voltar
                                        <span aria-hidden="true"> &rarr;</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div className="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={registerimage}
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}
