import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { confirmEmail } from '../../Requests/User/UserRequests';
import registerimage from "../../Assets/human1.png";
import Logo_Vertical_SF from "../../Assets/Logo_Vertical_SF.png";
import ErrorModal from '../../Components/ErrorModal';
import { useTranslation } from 'react-i18next';
import SuccesModal from '../../Components/SuccesModal';

function EmailConfirmation() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [feedbackModal, setFeedbackModal] = useState(null);
    const { t } = useTranslation();

    const removeQueryParams = () => {
        setFeedbackModal(false)
        const confirmation_token = searchParams.get(
            "confirmation_token"
        );
        confirmEmail(confirmation_token).then((res) => {
            setFeedbackModal(<SuccesModal
                title={t('EmailConfirmPage.SuccessModalTitle')}
                body={t('EmailConfirmPage.SuccessModalText')}
                continue={t('EmailConfirmPage.SuccessModalButton')}
                navLink="/login"
            />)
        }).catch((error) => {
            setFeedbackModal(<ErrorModal
                title={t('EmailConfirmPage.FailModalTitle')}
                body={t('EmailConfirmPage.FailModalText')}
                continue={t('EmailConfirmPage.FailModalButton')}
                navLink="/login"
            />)
        })

    };

    useEffect(() => {
        removeQueryParams();
    }, []);
    return (
        <>
            <div className="flex min-h-full">
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Logo_Vertical_SF} alt="Your Company" />
                            </div>
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                                {t('EmailConfirmPage.pageTitle')}
                            </h2>
                            <h2 className="mt-6 text-base font-bold tracking-tight text-gray-900">
                                {t('EmailConfirmPage.pageDescription')}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={registerimage}
                        alt=""
                    />
                </div>
            </div>
            {feedbackModal}
        </>
    );
}

export default EmailConfirmation