import React from 'react'
import bg_image from "../../../Assets/certificate-bg.png"
import logo from "../../../Assets/Logo_Vertical_SF.png"
import facebookIcon from "../../../Assets/facebookIcon.png"
import instagramIcon from "../../../Assets/instagramIcon.png"

import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import GlacialIndifferenceRegular from '../../../Assets/Fonts/GlacialIndifference-Regular.otf'
import GlacialIndifferenceBold from '../../../Assets/Fonts/GlacialIndifference-Bold.otf'
import GlacialIndifferenceItalic from '../../../Assets/Fonts/GlacialIndifference-Italic.otf'
import VidalokaRegular from '../../../Assets/Fonts/Vidaloka-Regular.ttf'

Font.register({
    family: 'GlacialIndifferenceRegular',
    format: "truetype",
    src: GlacialIndifferenceRegular,
    fontStyle: 'normal',
    fontWeight: 'normal'
});
Font.register({

    family: 'GlacialIndifferenceBold',
    format: "truetype",
    src: GlacialIndifferenceBold,
    fontStyle: 'normal',
    fontWeight: 'normal'

});

Font.register({

    family: 'GlacialIndifferenceItalic',
    format: "truetype",
    src: GlacialIndifferenceItalic,
    fontStyle: 'normal',
    fontWeight: 'normal'

});

Font.register({

    family: 'VidalokaRegular',
    format: "truetype",
    src: VidalokaRegular,
    fontStyle: 'normal',
    fontWeight: 'normal'

});

const styles = StyleSheet.create({

    page: {
        fontFamily: 'GlacialIndifferenceRegular',
        fontFamily: 'GlacialIndifferenceBold',
        fontFamily: 'GlacialIndifferenceItalic'
    }
});

function CompletionCertificate({ userName, courseName, courseDate, courseDuration, courseTeacher }) {
    return (
        <Document>
            <Page size="A4" style={{ width: "500 px", height: "100%" }} orientation="landscape">
                <View wrap={false} style={{ height: "100%" }}>
                    <div style={{ position: "relative", textAlign: "center" }}>
                        <div fixed
                            style={{
                                position: "absolute",
                                top: 6,
                                width: "100%",
                                objectFit: "contain",
                            }} >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: "center",
                                justifyContent: "center",
                                lineHeight: "1.4",
                            }}>
                                <Image src={logo} style={{ width: "350px", height: "auto", objectFit: "contain", marginBottom: "0px" }} />
                                <Text style={{ marginTop: "0px", fontSize: "24px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>Certifica que </Text>
                                <Text style={{ marginTop: "14px", fontSize: "40px", textAlign: "center", fontFamily: "VidalokaRegular", marginLeft: "128px", marginRight: "128px" }}>{userName}</Text>
                                <Text>
                                    <Text style={{ marginTop: "12px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>esteve presente no webinar </Text>
                                    <Text style={{ fontFamily: "GlacialIndifferenceItalic" }}>{courseName}</Text>
                                </Text>
                                <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>

                                    <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>promovido pela</Text>

                                    <Text style={{ fontFamily: "GlacialIndifferenceBold" }}> Academia imPHorma </Text>
                                    {!courseTeacher && <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>no dia </Text>}
                                    {!courseTeacher &&
                                        <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>{courseDate.split(" ")[0]}</Text>}
                                </Text>
                                {courseTeacher &&
                                    <Text>
                                        <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>e facilitado por </Text>
                                        <Text style={{ fontFamily: "GlacialIndifferenceItalic" }}>{courseTeacher}</Text>
                                        <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>  no dia </Text>
                                        <Text style={{ marginTop: "0px", fontSize: "17.83px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>{courseDate.split(" ")[0]}</Text>
                                    </Text>}
                                <Text>
                                    <Text style={{ marginTop: "0px", fontSize: "16px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>às {courseDate.split(" ")[1].split(":")[0]} horas </Text>
                                    <Text style={{ marginTop: "0px", fontSize: "16px", textAlign: "center", fontFamily: "GlacialIndifferenceRegular" }}>com duração de {courseDuration > 1 ? courseDuration + " horas" : courseDuration + " hora"}</Text>
                                </Text>

                            </div>
                        </div>
                        <div style={{
                            position: "absolute",
                            bottom: 0,
                            color: "black",
                            width: "100%",
                            height: "12%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            fontSize: "24px",
                            paddingLeft: 48,
                            paddingRight: 48,
                        }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Image src={facebookIcon} style={{ width: "32px", height: "auto", objectFit: "contain", marginRight: "10px", fontFamily: "GlacialIndifferenceRegular" }} />
                                <Text style={{ fontSize: "16px" }}>
                                    Academia ImPhorma
                                </Text>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Image src={instagramIcon} style={{ width: "32px", height: "auto", objectFit: "contain", marginRight: "10px", fontFamily: "GlacialIndifferenceRegular" }} />
                                <Text style={{ fontSize: "16px" }}>@academiaimphorma</Text>
                            </div>
                            {/*<Text wrap={true} style={{ width: "95%" }}> The 12 weeks guide </Text>*/}

                        </div>
                        {/* TODO Clean me */}
                        <Image src={bg_image} style={{ objectFit: "cover", width: "100%", height: "100%", zIndex: -1 }}></Image>
                    </div>
                </View>
            </Page>
        </Document >
    )
}

export default CompletionCertificate

/*

 <div className='flex items-center justify-center relative'>
            <img src={bg_image} className=" inset-0 object-contain object-center" />
            <div className="absolute inset-0 text-center z-10">
                teste
            </div>
        </div>
*/