import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getQuestions, submitQuestionair } from '../../../Requests/User/QuestionairRequests';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import passed from "../../../Assets/passed.png"
import failed from "../../../Assets/failed.png"

export default function Questionair({ fullBar }) {
    const { course_id } = useParams();
    const navigate = useNavigate()
    const [questions, setQuestions] = useState(null)
    const [answers, setAnswers] = useState([])
    const [result, setResult] = useState(null)
    const [course, setCourse] = useState(null)

    useEffect(() => {
        if (!course_id) {
            navigate("/courses")
        } else {
            getQuestions(course_id).then((res) => {
                setQuestions(res.data.questions)
                setCourse(res.data.course)
            }).catch(() => {
                navigate("/mycourses")
            })
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        let data = {
            "answers": JSON.stringify(answers),
            course_id: course_id
        }
        submitQuestionair(data).then((res) => {
            setResult(res.data)
        })
    }

    return (
        <div className={fullBar ? 'md:pl-64 h-1/3 xl:h-3/12' : 'md:pl-16 h-1/5 xl:h-3/12'}>
            <div className='max-w-full h-full '>
                <div className='w-full h-full flex flex-col bg-gray-800 h-fit py-8'>
                    <div className='grow flex items-center justify-start text-3xl z-20 px-4 lg:px-48'>
                        <div className='flex flex-col md:flex-row gap-4 items-center'>
                            <img src={course?.image} className='w-48 rounded-lg'></img>
                            <div className='w-full flex flex-col items-start justify-center '>
                                <span className='text-iteal text-2xl md:text-4xl font-bold'>{course?.name}</span>
                                <div className='font-thin text-xl md:text-3xl text-center text-white'>Avaliação </div>
                            </div>

                        </div>
                    </div>
                </div>
                {result ?
                    <div className='flex flex-1 flex-col h-full mx-2 lg:mx-8 xl:mx-auto max-w-7xl'>

                        {result.result ?
                            <div>
                                <div className='flex flex-col justify-center items-center my-4 gap-4'>
                                    <div className='text-5xl'><span className='text-iteal font-bold'>Aprovado!</span> </div>
                                    <div>Pode agora aceder ao seu certificado</div>
                                </div>
                                <div className='w-full justify-center flex-col'>
                                    <div className='flex w-full justify-center '>
                                        <img src={passed} className='max-w-md' />
                                    </div>
                                </div>
                                <div className='w-full flex justify-center'>
                                    <div
                                        className="flex w-fit justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-d-iteal focus:ring-offset-2"
                                        onClick={() => {
                                            navigate("/mycourses")
                                        }}
                                    >
                                        Certificado
                                    </div>
                                </div>
                            </div> :
                            <div>
                                <div className='flex flex-col justify-center items-center my-4 gap-4'>
                                    <div className='text-5xl'><span className='text-red-600 font-bold'>Reprovado!</span> </div>
                                    <div>Reveja o curso e volte a repetir a avaliação</div>
                                </div>
                                <div className='w-full justify-center flex-col'>
                                    <div className='flex w-full justify-center '>
                                        <img src={failed} className='max-w-md' />
                                    </div>
                                </div>
                                <div className='w-full flex justify-center gap-8'>
                                    <div className='w-fit flex justify-center'>
                                        <button
                                            className="flex w-fit justify-center rounded-md border border-transparent bg-d-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-d-iteal focus:ring-offset-2"
                                            onClick={() => {
                                                setResult(null)
                                                setAnswers([])
                                            }}
                                        >
                                            Repetir agora
                                        </button>
                                    </div>
                                    <div className='w-fit flex justify-center'>
                                        <button
                                            className="flex w-fit justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-d-iteal focus:ring-offset-2"
                                            onClick={() => {
                                                navigate("/video/" + course?.link)
                                            }}
                                        >
                                            Rever curso
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div> :
                    <div className='flex flex-1 flex-col h-full mx-2 lg:mx-8 xl:mx-auto max-w-7xl'>
                        <div className="border-b border-gray-200 pb-5 mt-12">
                            <h3 className="text-3xl font-medium leading-6 text-gray-900">Avaliação </h3>
                        </div>
                        {questions && questions.map((question) => {
                            return <MultipleChoiceQuestion question={question} setAnswers={setAnswers} />
                        })
                        }
                        <div className='w-full flex justify-end'>
                            <div
                                className="flex w-fit justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-d-iteal focus:ring-offset-2"
                                onClick={handleSubmit}
                            >Submeter</div>
                        </div>
                    </div>
                }
            </div >
        </div >

    )
}
