import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../Requests/User/UserRequests";

import Logo_Vertical_SF from "../../Assets/Logo_Vertical_SF.png";
import SuccesModal from "../../Components/SuccesModal";
import ErrorModal from "../../Components/ErrorModal";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { sendAmplitudeData } from "../../utils/amplitude";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import registerimage from "../../Assets/register.png";

const funs = [
  { id: 1, name: 'Diretor Técnico' },
  { id: 2, name: 'Farmacêutico' },
  { id: 3, name: 'Técnico de Farmácia' },
  { id: 4, name: 'Técnico Auxiliar de Farmácia' },
  { id: 5, name: 'Estudante' },
  { id: 6, name: 'Outro' },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Register() {
  const [query, setQuery] = useState('')
  const [selectedFun, setSelectedFun] = useState(null)

  const filteredFun =
    query === ''
      ? funs
      : funs.filter((fun) => {
        return fun.name.toLowerCase().includes(query.toLowerCase())
      })
  const [goToLogin, setGoToLogin] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let handleSubmit = (event) => {
    setFeedbackModal(false)
    setIsLoading(true);
    event.preventDefault();
    let response = register(
      event.target.name.value,
      event.target.email.value,
      event.target.password.value,
      event.target.password_confirmation.value,
      event.target.cp.value,
      event.target.location.value,
      event.target.nif.value,
      selectedFun?.name
    );
    response.then((res) => {
      setIsLoading(false);
      setFeedbackModal(
        <SuccesModal
          title={t('RegisterPage.SuccessModalTitle')}
          body={t('RegisterPage.SuccessModalText')}
          continue={t('RegisterPage.SuccessModalButton')}
          navLink="/login"
        />
      );
    })
      .catch((error) => {
        setIsLoading(false)
        error?.response?.data?.errors?.email ? (
          setFeedbackModal(
            <ErrorModal
              title={t('RegisterPage.FailModalTitle')}
              body={error?.response?.data?.errors?.email}
              continue={t('RegisterPage.FailModalButton')}
            />
          )) : error?.response?.data?.errors?.password ? (
            setFeedbackModal(
              <ErrorModal
                title={t('RegisterPage.FailModalTitle')}
                body={error?.response?.data?.errors?.password}
                continue={t('RegisterPage.FailModalButton')}
              />
            )) : <ErrorModal
          title={t('RegisterPage.FailModalTitle')}
          body={t('RegisterPage.FailModalText')}
          continue={t('RegisterPage.FailModalButton')}
        />

      });
  };

  function redirectToLogin() {
    setGoToLogin(true);
  }

  useEffect(() => {
    if (goToLogin) {
      navigate(`/login`);
    }
    sendAmplitudeData({ "type": "Navigation", "value": "Register" })
  }, [goToLogin]);

  return (
    <>
      <div className="flex min-h-full">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={registerimage}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center py-8 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className="w-72" src={Logo_Vertical_SF} alt="Your Company" />
              </div>
              <h2 className=" text-3xl font-bold tracking-tight text-gray-900">
                {t('RegisterPage.PageTitle')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-2 w-full" onSubmit={handleSubmit}>
                  <div className="mt-0">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.NameField')}<span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-0">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.EmailField')}<span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.PasswordField')}<span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.ConfirmPasswordField')}<span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="mt-0">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Localização<span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="location"
                        name="location"
                        type="string"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-0">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      NIF
                    </label>
                    <div className="mt-1">
                      <input
                        id="nif"
                        name="nif"
                        type="number"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>
                  <Combobox as="div" value={selectedFun} onChange={setSelectedFun}>
                    <Combobox.Label className="block text-sm font-medium text-gray-700">Função</Combobox.Label>
                    <div className="relative mt-1">
                      <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-iteal focus:outline-none focus:ring-1 focus:ring-iteal sm:text-sm"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(fun) => fun?.name}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Combobox.Button>

                      {filteredFun.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredFun.map((fun) => (
                            <Combobox.Option
                              key={fun.id}
                              value={fun}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-default select-none py-2 pl-3 pr-9',
                                  active ? 'bg-iteal text-white' : 'text-gray-900'
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <span className={classNames('block truncate', selected && 'font-semibold')}>{fun.name}</span>

                                  {selected && (
                                    <span
                                      className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-iteal-600'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                  </Combobox>
                  <div className="mt-0">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Carteira Profissional
                    </label>
                    <div className="mt-1">
                      <input
                        id="cp"
                        name="cp"
                        type="number"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-iteal focus:outline-none focus:ring-iteal sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="text-sm">Ao clicar em registar reconheces que aceitas a nossa <a href='/PP.pdf' download className="text-iteal">Politica de Privacidade</a> e os nossos  <a href='/T&C.pdf' download className="text-iteal">Termos e condições</a></div>
                  <div><button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-iteal py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-d-iteal focus:outline-none focus:ring-2 focus:ring-iteal focus:ring-offset-2"
                  >
                    {isLoading ? <Loader /> :
                      t('RegisterPage.SubmitButton')
                    }
                  </button>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        onClick={redirectToLogin}
                        className="font-medium text-d-iteal hover:text-iteal"
                      >
                        {t('RegisterPage.LoginLink')}
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {feedbackModal}
      </div>
    </>
  );
}

export default Register;
