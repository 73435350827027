import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function courseCheckout(course_id) {
    var url = process.env.REACT_APP_API_BASE_URL + "/buy_course/" + course_id;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}
